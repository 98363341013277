<template>
	<div class="wrapper">
		<input
			:id="_id"
			:checked="checked"
			class="checkbox"
			type="checkbox"
			@change="$emit('change', $event.target.checked)"
		/>
		<label :for="_id" class="switch"></label>
	</div>
</template>

<script>
export default {
	name: 'Toggle',
	model: {
		prop: 'checked',
		event: 'change',
	},
	props: {
		// Pass in an ID so another DOM element can use the `for` attribute to toggle the checkbox
		// Uses the internal vue UID if not provided
		id: {
			type: String,
			default: '',
		},
		checked: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		_id() {
			return this.id || this._uid
		},
	},
}
</script>

<style lang="scss" scoped>
$height: 30px;
$width: 54px;
$padding: 2px;
$t: 0.2s;

.wrapper {
	display: inline-block;
	height: $height;
}

.switch {
	position: relative;
	display: inline-block;
	width: $width;
	height: $height;
	background-color: var(--toggle-bg);
	border-radius: $height;
	transition: background-color $t;
}
// Circle button
.switch::after {
	content: '';
	position: absolute;
	width: $height - (2 * $padding);
	height: $height - (2 * $padding);
	border-radius: 50%;
	background-color: var(--toggle-handle);
	top: $padding;
	left: $padding;
	transition: left $t;
}

.checkbox:checked + .switch::after {
	left: $width - $height + $padding;
}
.checkbox:checked + .switch {
	background-color: var(--toggle-bg-active);
}
.checkbox {
	display: none;
}
</style>
