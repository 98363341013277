<template>
	<div>
		<strong>Window Width/Center Preset Shortcuts</strong>
		<table class="data-table table--full-width">
			<thead>
				<tr v-if="!mq.medium">
					<th>Key</th>
					<th>Name</th>
					<th>WW / WC</th>
				</tr>
				<tr v-else>
					<th></th>
					<th>Key</th>
					<th>Name</th>
					<th>{{ mq.large ? 'Window Width' : 'WW' }}</th>
					<th>{{ mq.large ? 'Window Center' : 'WC' }}</th>
					<th></th>
				</tr>
			</thead>
			<tbody v-if="loading">
				<tr>
					<td :colspan="!mq.medium ? 3 : 6" class="center loader" height="100px">
						<svg-icon icon="spinner-circle-thick" spin size="3em" />
					</td>
				</tr>
			</tbody>

			<tbody v-if="!mq.medium && !loading">
				<tr v-if="modalitySettings.length === 0">
					<td colspan="3" class="center">No Presets defined</td>
				</tr>
				<tr v-for="option in modalitySettings" :key="option.id" class="row-clickable" @click="editLevel(option)">
					<td>{{ option.key }}</td>
					<td>{{ option.name }}</td>
					<td>{{ option.windowWidth }} / {{ option.windowCenter }}</td>
				</tr>
				<tr>
					<td colspan="3" class="center" style="position: sticky;">
						<ast-button type="primary" @click.native="createLevel">
							<svg-icon icon="plus" />
							Add New Preset
						</ast-button>
					</td>
				</tr>
			</tbody>
			<tbody v-if="mq.medium && !loading">
				<tr v-if="modalitySettings.length === 0">
					<td colspan="6" class="center">No Presets defined</td>
				</tr>
				<tr v-for="option in modalitySettings" :key="option.id" :class="{ deleting: option.deleting }">
					<td>
						<button class="table-button danger" title="Delete" @click="deleteLevel(option)">
							<svg-icon icon="close" />
						</button>
					</td>
					<td>{{ option.key }}</td>
					<td>{{ option.name }}</td>
					<td>{{ option.windowWidth }}</td>
					<td>{{ option.windowCenter }}</td>
					<td>
						<button class="table-button primary" title="Edit" @click="editLevel(option)">
							<svg-icon icon="pencil" />
						</button>
					</td>
				</tr>
				<tr>
					<td colspan="6" class="center" style="position: sticky;">
						<ast-button type="primary" @click.native="createLevel">
							<svg-icon icon="plus" />
							Add New Preset
						</ast-button>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import api from '@services/api'
import AstButton from '@components/Button'
import { openEditLevelPreset } from '@dialogs/EditLevelPresetDlg.vue'
import { eventBus } from '@services/eventBus'

const { FETCH_LEVEL_PRESETS, VUEX_ACTION } = eventBus.type

export default {
	name: 'LevelPresetTable',
	components: {
		AstButton,
	},
	props: {
		modalityId: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			loading: true,
			modalitySettings: [],
		}
	},
	watch: {
		modalityId: {
			handler: 'fetchPresets',
			immediate: true,
		},
	},
	created() {
		eventBus.on(FETCH_LEVEL_PRESETS, this.fetchPresets)
	},
	destroyed() {
		eventBus.off(FETCH_LEVEL_PRESETS, this.fetchPresets)
	},
	methods: {
		async fetchPresets() {
			this.loading = true
			const response = await api.user.getWindowLevelPresets(this.modalityId)
			if (response.status === 200)
				this.modalitySettings = response.data.data.sort((a, b) => (a.key < b.key ? -1 : a.key > b.key ? 1 : 0))
			else {
				// TODO: error handling?
			}
			this.loading = false
		},
		async createLevel() {
			const shouldReload = await openEditLevelPreset(this.modalityId, this.usedKeys())
			if (shouldReload) {
				this.fetchPresets()
				this.syncWindowLevelPresets()
			}
		},
		async editLevel(presetObject) {
			const shouldReload = await openEditLevelPreset(this.modalityId, this.usedKeys(presetObject), presetObject)
			if (shouldReload) {
				this.fetchPresets()
				this.syncWindowLevelPresets()
			}
		},
		async deleteLevel(presetObject) {
			presetObject.deleting = true
			const resp = await api.user.deleteWindowLevelPreset(presetObject.id)
			if (resp.status === 200) {
				// this.modalitySettings = this.modalitySettings.filter(m => m.id !== presetObject.id)
				this.fetchPresets()
				this.syncWindowLevelPresets()
			} else {
				// TODO: Show error message?
			}
		},
		syncWindowLevelPresets() {
			eventBus.broadcast(FETCH_LEVEL_PRESETS)
			eventBus.broadcast(VUEX_ACTION, { type: 'refreshWindowLevelPresetsForAuthenticatedUser' })
		},
		usedKeys(preset) {
			return this.modalitySettings.filter(mod => !preset || mod.id !== preset.id).map(mod => mod.key)
		},
	},
}
</script>

<style lang="scss">
.hover-peek {
	opacity: 0;
	&:hover {
		opacity: 1;
	}
}

td.center {
	text-align: center;
}

tr.deleting {
	font-style: italic;
	color: var(--table-color-hover);
	pointer-events: none;
}

td.loader {
	animation: delayfadein 1s;
}

@keyframes delayfadein {
	0% {
		opacity: 0;
	}
	90% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.table-button {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 100%;
	background: var(--button-default-bg);
	color: var(--button-default-color);
	outline: none;
	border: 0;
	width: 24px;
	height: 24px;
	cursor: pointer;
	&.danger {
		&:hover {
			background: var(--button-danger-bg);
			color: var(--button-danger-color);
		}
	}
	&.primary {
		&:hover {
			background: var(--button-primary-bg);
			color: var(--button-primary-color);
		}
	}
}
</style>
