<template>
	<modal-wrapper :close-on-escape="false" :close-on-overlay-click="false" @close="cancel">
		<div class="dialog edit-level-presets">
			<dlg-header :title="title" @close="cancel()" />
			<div class="modal-body">
				<form @submit.prevent>
					<ast-input v-model="name" v-focus label="Name" />
					<div class="input-wrapper">
						<label class="input-label">Key</label>
						<hotkey-select v-model="key" :disabled-keys="existingKeys" />
					</div>
					<ast-input v-model.number="windowWidth" type="number" label="Window Width" />
					<ast-input v-model.number="windowCenter" type="number" label="Window Center" />
				</form>
			</div>
			<div class="footer">
				<div class="left">
					<button v-if="!isNew && !mq.medium" class="btn btn-danger" @click="deletePreset">
						Delete
					</button>
					<button class="btn btn-default" @click="cancel">Cancel</button>
				</div>
				<div class="right">
					<button class="btn btn-primary" @click="save">Save</button>
				</div>
			</div>
		</div>
		<div v-if="isLoading" class="loader">
			<svg-icon icon="spinner-circle-thick" spin size="3em" />
		</div>
	</modal-wrapper>
</template>

<script>
import ModalWrapper from '@components/ModalWrapper.vue'
import DlgHeader from '@components/DlgHeader.vue'
import AstInput from '@components/Input.vue'
import HotkeySelect from '@/settings/ViewerConfiguration/HotkeySelect.vue'
import api from '@services/api'

import { dlg } from '@utils/dlgUtils'

const EditLevelPreset = {
	name: 'EditLevelPreset',
	components: {
		ModalWrapper,
		DlgHeader,
		AstInput,
		HotkeySelect,
	},
	props: {
		modalityId: {
			type: Number,
			required: true,
		},
		preset: {
			type: Object,
			default: () => ({}),
		},
		existingKeys: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			windowWidth: '',
			windowCenter: 'null',
			name: '',
			key: 'None',
			isLoading: false,
		}
	},
	computed: {
		title() {
			return this.isNew ? 'Create Level Preset' : `Edit Level Preset: ${this.preset.name}`
		},
		isNew() {
			return !this.preset.id
		},
	},
	created() {
		if (!this.isNew) {
			// Cache input values
			this.windowWidth = this.preset.windowWidth
			this.windowCenter = this.preset.windowCenter
			this.key = this.preset.key
			this.name = this.preset.name
		}
	},
	methods: {
		async save() {
			this.isLoading = true
			const { modalityId, key, name, windowCenter, windowWidth } = this
			const presetObject = {
				modalityId,
				key,
				name,
				windowCenter,
				windowWidth,
			}
			if (!this.isNew) {
				presetObject.id = this.preset.id
			}
			const response = await api.user.updateWindowLevelPreset(presetObject)
			if (response.status === 200) {
				this.edited()
			} else {
				this.isLoading = false
				// TODO: show dialog error!
			}
		},
		async deletePreset() {
			this.isLoading = true
			await api.user.deleteWindowLevelPreset(this.preset.id)
			this.edited()
		},
		edited() {
			this.isLoading = false
			dlg.close(this, true, true)
		},
		cancel() {
			this.isLoading = false
			dlg.close(this, true, false)
		},
	},
}
export default EditLevelPreset

export function openEditLevelPreset(modalityId, existingKeys, preset) {
	return dlg.open(EditLevelPreset, { modalityId, existingKeys, preset })
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.dialog {
	position: relative;
}
.modal-body {
	@media (min-width: $mqSmall) {
		min-width: 320px;
	}
}
.footer {
	display: flex;
	flex-direction: row;
	.left {
		text-align: left;
	}
	.right {
		margin-left: auto;
	}
}
.loader {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(0, 0, 0, 0.2);
	pointer-events: none;
	animation: delayfadein 1s;
}

form {
	.input-wrapper {
		margin-bottom: 5px;
	}
}
// $padding: 5px;
// @media (min-width: $mqSmall) {
// 	form {
// 		display: flex;
// 		flex-wrap: wrap;
// 		margin: 0 (-$padding);
// 		.input-wrapper {
// 			margin: 0 $padding $padding;
// 		}
// 		.f75 {
// 			flex-basis: calc(75% - #{$padding * 2});
// 		}
// 		.f50 {
// 			flex-basis: calc(50% - #{$padding * 2});
// 		}
// 		.f25 {
// 			flex-basis: calc(25% - #{$padding * 2});
// 		}
// 	}
// }

@keyframes delayfadein {
	0% {
		opacity: 0;
	}
	90% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>
