<template>
	<div class="layout-grid" @mouseleave="onHover(0, 0)">
		<template v-for="row in maxRows">
			<div
				v-for="col in maxColumns"
				:key="(row - 1) * maxColumns + col"
				class="cell"
				:class="{
					disabled: isCellDisabled(row, col),
					hovered: hover.r >= row && hover.c >= col,
					selected: value.r >= row && value.c >= col,
				}"
				@mouseover.stop="onHover(row, col)"
				@click="onSelect(row, col)"
			></div>
		</template>
	</div>
</template>

<script>
const disabledOptions = ['4x6']

export default {
	name: 'LayoutGridSelector',
	props: {
		value: {
			type: Object,
			default: () => ({ r: 1, c: 1 }),
		},
	},
	data() {
		return {
			hover: { r: 0, c: 0 },
		}
	},
	created() {
		// Static
		this.maxRows = 4
		this.maxColumns = 6
	},
	methods: {
		isCellDisabled(row, col) {
			if (disabledOptions.includes(`${row}x${col}`)) return true
			return false
		},
		onHover(r, c) {
			this.hover = { r, c }
		},
		onSelect(r, c) {
			this.$emit('input', { r, c })
			this.hover = { r: 0, c: 0 }
		},
	},
}
</script>

<style lang="scss" scoped>
.layout-grid {
	display: inline-grid;
	grid: repeat(4, 24px) / repeat(6, 24px);
	grid-gap: 2px;
	margin: 0 auto;
}
.cell {
	border: 1px solid var(--secondary-label);
	cursor: pointer;
	height: 100%;
	&.disabled {
		border-color: var(--tertiary-border);
		background-color: var(--primary-bg);
		pointer-events: none;
	}
	&.hovered {
		border-color: var(--button-primary-bg);
		border-width: 2px;
	}
	&.selected {
		border-color: var(--button-primary-bg);
		background-color: var(--button-primary-bg-hover);
	}
	&.selected.hovered {
		background-color: var(--primary-toolbar-logo);
	}
}
</style>
