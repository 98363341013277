<template>
	<div class="viewer-config">
		<tabs v-model="activeTab" offset="74px" :tabs="tabItems" low-contrast />
		<div class="layout-leveling">
			<div v-if="!mq.small" class="modalities">
				<div class="mobile-header">
					<label>Modality:</label>
					<modality-select v-model="activeModalityId" />
				</div>
			</div>
			<div v-if="mq.small" class="modalities">
				<!-- Removed temporarily, implement with ch7534 -->
				<!-- <div
					class="pill"
					:class="{ active: activeModalityId === null }"
					@click="selectModality('Default')"
				>
					Default
				</div> -->
				<div
					v-for="modality in modalityOptions"
					:key="modality.id"
					class="pill"
					:class="{ active: modality.id === activeModalityId }"
					@click="selectModality(modality.id)"
				>
					{{ modality.name }}
				</div>
			</div>
			<div class="modality-editor">
				<level-preset-table v-show="levelActive" :modality-id="activeModalityId" />
				<layout-presets v-show="layoutActive" :modality-id="activeModalityId" />
			</div>
		</div>

		<ul class="list" style="margin-top: 1em;">
			<li v-if="allowOpenStudiesInNewWindow">
				<label for="new-window">
					Open studies in a new Window
					<span class="summary">On desktops and laptops</span>
				</label>

				<toggle id="new-window" :checked="openStudiesInNewWindow" @change="saveOpenStudiesInNewWindow($event)" />
			</li>
			<li>
				<label for="load-from-middle">
					Open image stacks to the middle slice
					<span class="summary">For CT, PT, and MR studies, open to the middle slice and load outward</span>
				</label>

				<toggle id="load-from-middle" :checked="loadSeriesFromMiddle" @change="setLoadSeriesFromMiddle($event)" />
			</li>
		</ul>
	</div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import LevelPresetTable from './LevelPresetTable.vue'
import LayoutPresets from './LayoutPresets.vue'
import ModalitySelect from '@components/ModalitySelect.vue'
import Toggle from '@components/Toggle.vue'
import Tabs from '@components/Tabs.vue'

export default {
	name: 'ViewerConfiguration',
	components: { LevelPresetTable, LayoutPresets, ModalitySelect, Toggle, Tabs },
	props: {
		initialTab: {
			type: String,
			default: 'leveling',
		},
	},
	data() {
		return {
			activeTab: '',
			activeModalityId: 0,
			tabItems: [
				{
					id: 'layout',
					label: 'Viewer Layout',
				},
				{
					id: 'leveling',
					label: 'Leveling Presets',
				},
			],
		}
	},
	computed: {
		...mapState({
			modalityOptions: state => state.static.modalities.filter(m => m.isInUse),
			openStudiesInNewWindow: state => state.windows.openStudiesInNewWindow,
			loadSeriesFromMiddle: state => state.viewer.loadSeriesFromMiddle !== false,
		}),
		...mapGetters(['allowOpenStudiesInNewWindow']),
		mobileModalities() {
			// Implement 'default' modality with ch7534
			// return [{ id: null, name: 'Default' }, ...this.modalityOptions]
			return [...this.modalityOptions]
		},
		layoutActive() {
			return this.activeTab === 'layout'
		},
		levelActive() {
			return this.activeTab === 'leveling'
		},
	},
  watch: {
    isZoomSync() {
      this.updateToolBindings()
    }
  },
	created() {
		if (this.modalityOptions.length) this.activeModalityId = this.modalityOptions[0].id
		this.activeTab = this.initialTab
	},
	beforeDestroy() {
		// If the viewer is open, refetch and rebind WL presets
		this.$store.dispatch('refreshWindowLevelPresetsForAuthenticatedUser')
	},
	methods: {
		...mapActions(['saveOpenStudiesInNewWindow', 'setLoadSeriesFromMiddle']),
		selectModality(modalityId) {
			this.activeModalityId = modalityId
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.layout-leveling {
	display: flex;
	flex-direction: column;
	background: var(--secondary-bg);

	.modalities {
		padding: 8px;
	}
	.mobile-header {
		display: flex;
		flex-direction: row;
		align-items: center;

		label {
			margin-right: 8px;
		}
		.v-select {
			flex-grow: 1;
		}
	}
	.pill {
		padding: 6px 14px;
		border-radius: 100px;
		background-color: var(--tertiary-bg);
		color: var(--button-default-color);
		text-align: center;
		&.active {
			background-color: var(--button-default-color);
			color: var(--button-default-bg);
		}
		&:hover:not(.active) {
			background-color: var(--button-default-color);
			color: var(--button-default-bg);
		}
		& + .pill {
			margin-top: 8px;
		}
	}

	@media (min-width: $mqSmall) {
		flex-direction: row;
		max-height: 600px;
		.modalities {
			overflow-y: auto;
			padding: 8px 6px;
			border-right: 1px solid var(--primary-border);
		}
		.modality-editor {
			flex-grow: 1;
			overflow-y: auto;
		}
	}
}

ul.list {
	list-style: none;
	background-color: var(--secondary-bg);

	li {
		display: flex;
		flex-direction: row;
		align-items: center;
		padding-right: 8px;
		&:not(:last-child) {
			border-bottom: 1px solid var(--table-row-border);
		}
		> label {
			// Mimic table row height
			padding: 13px 8px;
			flex-grow: 1;
			.summary {
				font-size: small;
				font-style: italic;
				display: block;
				margin: 3px 0 0 8px;
				opacity: 0.8;
			}
		}
	}
}
</style>

<style lang="scss">
.viewer-config {
	strong {
		text-align: center;
		display: block;
		margin: 1em 0;
	}
}
</style>
