<template>
	<div class="layout-presets">
		<strong>Viewer Layout Settings - {{ modalityName }}</strong>
		<checkbox :value="isDefault" style="padding-right: 0" @input="toggleDefault($event)">
			Use Default
		</checkbox>
		<layout-grid-selector :value="activeLayoutPreset" @input="savePreset($event)" />
		<div v-if="!isDefault"> {{ activeLayoutPreset.r }} Rows &times; {{ activeLayoutPreset.c }} Columns </div>
	</div>
</template>

<script>
import { mapState } from 'vuex'
import api from '@services/api'
import LayoutGridSelector from '@components/layout/LayoutGridSelector.vue'
import Checkbox from '@components/Checkbox.vue'
import { eventBus } from '@services/eventBus'

const EVENT_FETCH_LAYOUT_PRESET = eventBus.type.FETCH_LAYOUT_PRESET

export default {
	name: 'LayoutPresetTable',
	components: {
		LayoutGridSelector,
		Checkbox,
	},
	props: {
		modalityId: {
			type: Number,
			default: null,
		},
	},
	data() {
		return {
			loading: false,
			// Start with 0,0 if unset
			activeLayoutPreset: { c: 0, r: 0 },
		}
	},
	computed: {
		...mapState({
			modalityOptions: state => state.static.modalities,
		}),
		modalityName() {
			const modality = this.modalityOptions.find(m => m.id === this.modalityId)
			return (modality && modality.name) || ''
		},
		isDefault() {
			return this.activeLayoutPreset.c === 0 && this.activeLayoutPreset.r === 0
		},
	},
	watch: {
		modalityId: {
			handler: 'fetchPreset',
			immediate: true,
		},
	},
	created() {
		eventBus.on(EVENT_FETCH_LAYOUT_PRESET, this.fetchPreset)
	},
	destroyed() {
		eventBus.off(EVENT_FETCH_LAYOUT_PRESET, this.fetchPreset)
	},
	methods: {
		async fetchPreset() {
			this.loading = true
			const response = await api.user.getLayoutPreset(this.modalityName)
			if (response.status === 200) {
				const { seriesRows: r, seriesColumns: c } = response.data
				this.activeLayoutPreset = { r, c }
			} else if (response.status === 204) {
				this.activeLayoutPreset = { r: 0, c: 0 }
			}
			this.loading = false
		},
		async savePreset(newPreset) {
			if (!this.loading) {
				// save the layout to the DB with the given modalityID
				const data = {
					modality: this.modalityName,
					seriesRows: newPreset.r,
					seriesColumns: newPreset.c,
				}
				// Assume it will work, to prevent flashing UI
				const backup = { ...this.activeLayoutPreset }
				this.activeLayoutPreset = { ...newPreset }
				const response = await api.user.setLayoutPreset(data)
				if (response.status === 200) {
					this.fetchPreset()
					eventBus.broadcast(EVENT_FETCH_LAYOUT_PRESET)
				} else {
					// Backout if the fetch failed for whatever reason
					this.activeLayoutPreset = backup
				}
			}
		},
		async toggleDefault(useDefault) {
			if (useDefault) {
				const response = await api.user.deleteLayoutPreset(this.modalityName)
				if (response.status === 200) {
					this.activeLayoutPreset = { r: 0, c: 0 }
					eventBus.broadcast(EVENT_FETCH_LAYOUT_PRESET)
				}
			}
		},
	},
}
</script>

<style lang="scss" scoped>
.layout-presets {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
</style>
